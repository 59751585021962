import React, { useState } from 'react'
import { MqDesktop } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import { openNewsletterConfirmation } from '~utils/index'
import { NewsletterCaptcha as PrestyledNewsletterCaptcha } from '../../NewsletterCaptcha'

export function StayInformedNewsletterForm() {
    const [email, setEmail] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [showCaptcha, setShowCaptcha] = useState(false)

    const canSubmit = !!email && !isSubmitting && !showCaptcha

    const [form, setForm] = useState(null)

    return (
        <StayInformedNewsletterFormRoot
            ref={setForm}
            onSubmit={async (e) => {
                e.preventDefault()

                if (!canSubmit) {
                    return
                }

                setShowCaptcha(true)
            }}
        >
            <Fields>
                <EmailInput
                    readOnly={showCaptcha}
                    aria-label="email"
                    aria-required="true"
                    type="email"
                    data-inputmask=""
                    name="fields[email]"
                    placeholder="Enter your email*"
                    autoComplete="email"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    value={email}
                />
                <SubmitButton type="submit" disabled={!canSubmit}>
                    {showCaptcha || isSubmitting ? 'Submitting…' : 'Subscribe'}
                </SubmitButton>
            </Fields>
            {showCaptcha && (
                <NewsletterCaptcha
                    form={form}
                    onBeforeSubmit={() => {
                        setIsSubmitting(true)
                    }}
                    onAfterSubmit={() => {
                        setEmail('')

                        openNewsletterConfirmation()
                    }}
                    onDone={() => {
                        setIsSubmitting(false)

                        setShowCaptcha(false)
                    }}
                />
            )}
        </StayInformedNewsletterFormRoot>
    )
}

const NewsletterCaptcha = styled(PrestyledNewsletterCaptcha)`
    margin-top: 0.5rem;
`

const EmailInput = styled.input`
    flex-grow: 1;
    outline: 0;
    border: 0;
`

const SubmitButton = styled.button`
    appearance: none;
    color: white;
    border: 0;
    background: #0324ff;
    border-radius: 0.25rem;
    padding: 0 0.75rem;
    font-size: 0.75rem;
    height: 2.5rem;
    width: auto;

    &:focus,
    &:active {
        transform: scale(1);
    }

    @media ${MqDesktop} {
        padding: 0 26px;
        font-size: 14px;
    }
`

const Fields = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
`

const StayInformedNewsletterFormRoot = styled.form`
    position: relative;
    background-color: #ffffff;
    padding: 0.5rem;
    border-radius: 0.375rem;
`
